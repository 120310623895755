import { Component } from '@angular/core';
import { HealthService } from '@core/services/health.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent {

  get lastCheck() {
    return this.healthService.lastCheck;
  }

  get status$() {
    return this.healthService.statuses$;
  }

  constructor(
    private healthService: HealthService,
  ) { }

}
