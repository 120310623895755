import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-anonymous-users',
  templateUrl: './anonymous-users.component.html',
  styleUrls: ['./anonymous-users.component.scss']
})
export class AnonymousUsersComponent implements OnInit {

  // @ViewChild('searchEmail', { static: true }) searchEmail: ElementRef;

  users: any;
  testStripe = true;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.load();
  }

  async load() {
    const resp = await this.http
      .get<{ users: any[] }>(`${environment.config.endpoints.billing}/billing/users/pending-all`)
      .toPromise();
    this.users = resp.users;
    this.users.sort((a, b) => a.created > b.created ? -1 : 1);
  }
}
