import { Build } from '../app/@shared/models/build';
import { Config } from '../app/@shared/models/config';
import * as build from '../build.json';

declare var window: any;

class Environment {
  production = false;

  build: Build = build['default'];

  configUrl = 'config.json';
  dynamicConfigBaseUrl = 'https://assets.echofin.com/config';

  get config(): Config {
    return window.config || {};
  }
}

export const environment = new Environment();
