<app-page>
  <div class="toolbar">
    <div class="menu">
      <button class="btn btn-primary btn-sm" [disabled]="loading || (!missingKeys.length && !thisShouldBeThereKeys.length)" (click)="fix()">
        fix</button>
    </div>
    <div class="menu">
      <button class="btn btn-sm btn-outline-secondary border-0" (click)="execute()">
        <span class="fas fa-sync-alt" [ngClass]="{spin:loading}"></span>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header">Missing access</div>
    <ng-container *ngIf="missingKeys; else loader">
      <ng-container *ngIf="missingKeys.length; else noItems">
        <table class="table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>UserId</th>
              <th>planId</th>
              <th>acKey</th>
              <th>message</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let s of missingKeys;let i = index">
              <tr>
                <td>{{i+1}}</td>
                <td>{{s.userId}}</td>
                <td>{{s.planId}}</td>
                <td>{{s.acKey}}</td>
                <td>{{s.message}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>
  <div class="card">
    <div class="card-header">Having access when they shouldn't</div>
    <ng-container *ngIf="thisShouldBeThereKeys; else loader">
      <ng-container *ngIf="thisShouldBeThereKeys.length; else noItems">
        <table class="table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>UserId</th>
              <th>planId</th>
              <th>acKey</th>
              <th>message</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let s of thisShouldBeThereKeys;let i = index">
              <tr>
                <td>{{i+1}}</td>
                <td>{{s.userId}}</td>
                <td>{{s.planId}}</td>
                <td>{{s.acKey}}</td>
                <td>{{s.message}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>
</app-page>

<ng-template #loader>
  <div class="text-center text-muted p-4">
    loading...
  </div>
</ng-template>

<ng-template #noItems>
  <div class="text-center text-muted p-4">
    No missing access! 🎉🙌
  </div>
</ng-template>
