import { Component } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor(
    private themeService: ThemeService,
    private config: NgbTooltipConfig
  ) {
    config.container = 'body';

    document.body.setAttribute('data-theme', this.themeService.theme);
    this.themeService.theme$.subscribe(theme => {
      document.body.setAttribute('data-theme', theme);
    });
  }
}
