<div class="container px-0 py-4">
  <div class="card">
    <div class="card-header d-flex align-items-center">
      <span class="fas fa-heartbeat me-2"></span> Health
      <small *ngIf="lastCheck" class="ms-auto">
        LAST CHECK: {{ lastCheck | date: "HH:mm:ss" }}
      </small>
    </div>
    <ng-container *ngIf="(status$|async) as status">
      <ng-container *ngIf="status && status.length">
        <!-- <pre>{{status|json}}</pre> -->
        <table class="table">
          <thead>
            <tr>
              <th width="90px;">Status</th>
              <th>Name</th>
              <th width="160px;">Build</th>
              <th width="160px;">Core</th>
              <th width="160px;">Start</th>
              <th class="text-center" width="90px;">Req | Exc</th>
              <th width="160px;">Last Req</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let s of status">
              <tr>
                <td>
                  <ng-container [ngSwitch]="s.status">
                    <span *ngSwitchCase="'LoadFailed'" class="badge d-block rounded-pill bg-danger">
                      Failed</span>
                    <span *ngSwitchCase="'Running'" class="badge d-block rounded-pill bg-success">
                      Running</span>
                    <span *ngSwitchDefault class="badge d-block rounded-pill bg-secondary">
                      {{ s.status }}</span>
                  </ng-container>
                </td>
                <td>
                  <a [href]="s.healthUrl | safe" target="_blank">{{ s.name }}</a>
                </td>
                <td>
                  <ng-container *ngIf="s.buildAt">
                    <span [ngbTooltip]="s.buildAtDate | date: 'MM/dd HH:mm:ss'">
                      {{ s.buildAtDate | dfnsFormatDistanceToNow }}</span>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="s.coreAt">
                    <span [ngbTooltip]="s.coreAtDate | date: 'MM/dd HH:mm:ss'">
                      {{ s.coreAtDate | dfnsFormatDistanceToNow }}</span>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="s.startAt">
                    <span [ngbTooltip]="s.startAtDate | date: 'MM/dd HH:mm:ss'">
                      {{ s.startAtDate | dfnsFormatDistanceToNow }}</span>
                  </ng-container>
                </td>
                <td class="text-center">
                  <span ngbTooltip="requests">{{ s.totalRequests }}</span> |
                  <span ngbTooltip="exceptions">{{ s.exceptions }}</span>
                </td>
                <td>
                  <ng-container *ngIf="s.lastRequestAt">
                    <span [ngbTooltip]="
                          s.lastRequestAtDate | date: 'MM/dd HH:mm:ss'
                        ">
                      {{
                          s.lastRequestAtDate | dfnsFormatDistanceToNow
                        }}</span>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>
</div>
