import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteTeamFormComponent } from './delete-team-form/delete-team-form.component';

@Component({
  selector: 'app-delete-team',
  templateUrl: './delete-team.component.html',
  styleUrls: ['./delete-team.component.scss']
})
export class DeleteTeamComponent {

  constructor(
    private modal: NgbModal) { }

  openDelete() {
    this.modal.open(DeleteTeamFormComponent);
  }
}
