import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthenticationConfiguration, FilestackConfiguration, MessageConfiguration, NotificationConfiguration, ProfileConfiguration, ProspectConfiguration, SessionConfiguration, TeamConfiguration } from '@echofin/libraries';
import { AnonGuard } from './guards/anon.guard';
import { MainGuard } from './guards/main.guard';
import { authenticationConfigurationFactory, filestackConfigurationFactory, InitService, init_app, messageConfigurationFactory, notificationConfigurationFactory, profileConfigurationFactory, prospectConfigurationFactory, sessionConfigurationFactory, teamConfigurationFactory } from './init-functions';
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { AccessService } from './services/access.service';
import { AuthService } from './services/auth.service';
import { DynamicConfigService } from './services/dynamic-config.service';
import { HealthService } from './services/health.service';
import { NotificationsService } from './services/notifications.service';
import { TeamService } from './services/team.service';
import { ThemeService } from './services/theme.service';

@NgModule({
  providers: [
    AccessService,
    AuthService,
    TeamService,
    HealthService,
    NotificationsService,
    TokenInterceptor,
    MainGuard,
    AnonGuard,
    ThemeService,
    DynamicConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitService],
      multi: true,
    },
    { provide: AuthenticationConfiguration, useFactory: authenticationConfigurationFactory, deps: [InitService] },
    { provide: MessageConfiguration, useFactory: messageConfigurationFactory, deps: [InitService] },
    { provide: NotificationConfiguration, useFactory: notificationConfigurationFactory, deps: [InitService] },
    { provide: ProfileConfiguration, useFactory: profileConfigurationFactory, deps: [InitService] },
    { provide: ProspectConfiguration, useFactory: prospectConfigurationFactory, deps: [InitService] },
    { provide: SessionConfiguration, useFactory: sessionConfigurationFactory, deps: [InitService] },
    { provide: TeamConfiguration, useFactory: teamConfigurationFactory, deps: [InitService] },
    { provide: FilestackConfiguration, useFactory: filestackConfigurationFactory, deps: [InitService] },
  ]
})
export class CoreModule { }
