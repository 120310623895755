import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { ProfileMeServiceApi } from '@echofin/libraries';
import { User } from '@echofin/libraries/api/message/models/user';
import { environment } from '@env/environment';
import { Token } from '@shared/models/token';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string = null;
  tokenExp: number = null;

  sso: ElementRef<any>;
  ssoEvents$ = new Subject<any>();
  identity: string;

  user: User;

  constructor(
    private http: HttpClient,
    private userApi: ProfileMeServiceApi,

  ) {
    window.addEventListener('message', (e) => {
      // console.log("iframe message", e.data)
      this.ssoEvents$.next(e.data)
    }, false);
  }

  login(identity: string, password: string) {
    console.log('Login to echofin...');
    let loginTimeout = null;
    var promise = new Promise<void>((resolve, reject) => {
      this.ssoEvents$
        .pipe(
          filter(x => x.type.startsWith("loggedIn")),
          first(),
        )
        .subscribe((x) => {
          if (loginTimeout) clearTimeout(loginTimeout);
          if (x.type === 'loggedIn:success') {
            console.log('Login success...');
            resolve()
          } else if (x.errorCode === 'USER_OR_PASSWORD_WRONG') {
            reject('Wrong username/email or password. Please try again.')
          }
        }, () => {
          if (loginTimeout) clearTimeout(loginTimeout);
          reject()
        })

      loginTimeout = setTimeout(() => {
        reject(new Error('Could not login'));
      }, 5000);
    })
    this.postMessage({
      password,
      identity,
      type: 'login',
    });
    return promise
  }

  logout() {
    console.log('Logout from echofin...');
    let logoutTimeout = null;
    var promise = new Promise<void>((resolve, reject) => {
      this.ssoEvents$
        .pipe(
          filter(x => x.type.startsWith("loggedOut")),
          first(),
        )
        .subscribe((x) => {
          if (logoutTimeout) clearTimeout(logoutTimeout);
          if (x.type === 'loggedOut:success') {
            localStorage.clear();
            this.user = null
            resolve()
          } else {
            reject()
          }
        }, () => {
          if (logoutTimeout) clearTimeout(logoutTimeout);
          reject()
        })
        logoutTimeout = setTimeout(() => {
          resolve()
        }, 5000);
    })
    this.postMessage({
      type: 'logout',
    });
    return promise
  }

  async getUser() {
    if (this.user) return this.user;
    this.user = await this.userApi.GetMeProfile()
      .toPromise()
      .catch(() => null);
    return this.user;
  }

  async store(token: Token) {
    this.token = token.access_token;
    this.tokenExp = token.expires_in;
  }

  async getShortToken() {
    const response = await this.http
      .get(`${environment.config.endpoints.authentication}/token`)
      .toPromise();
    return response['token'];
  }

  getUserId() {
    return this.http
      .get(`${environment.config.endpoints.authentication}/authentication/check`)
      .toPromise()
      .catch(() => null);
  }

  postMessage(msg) {
    this.sso.nativeElement
      .contentWindow
      .postMessage(msg, '*');
  }
}


