<nav class="navbar navbar-expand navbar-dark bg-secondary sticky-top">
  <div class="container">
    <div class="collapse navbar-collapse">
      <div class="divider"></div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/tools/sub-ac-fix']" routerLinkActive="active">
            Missing Access</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/tools/reload']" routerLinkActive="active">
            Reload Teams</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/tools/delete-team']" routerLinkActive="active">
            Delete Team</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/tools/zombies']" routerLinkActive="active">
            Zombies Sessions</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
