import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html'
})
export class OfflineComponent implements OnInit {

  emojies = ['🎉', '🤢', '😫', '👿', '😢', '😭'];
  emoji;

  constructor() { }

  ngOnInit() {
    this.shuffle(this.emojies);
    this.emoji = this.emojies[0];
    const interv = interval(200)
      .subscribe((i) => {
        if (i > this.emojies.length - 2) {
          interv.unsubscribe();
          return;
        }
        this.emoji = this.emojies[i + 1];
      });
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

}
