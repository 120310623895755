<nav class="navbar navbar-expand navbar-dark bg-secondary sticky-top">
  <div class="container size-xxl">
    <div class="collapse navbar-collapse">
      <div class="divider"></div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/messages/overview']" routerLinkActive="active">Overview</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
