import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

export interface BillingAuditDto {
  users: number,
  missingKeys: any[],
  missingPrincipals: any[],
  thisShouldBeThereKeys: any[],
}

@Component({
  selector: 'app-sub-ac-fix',
  templateUrl: './sub-ac-fix.component.html',
})
export class SubAcFixComponent implements OnInit {

  missingKeys: any[];
  thisShouldBeThereKeys: any[];
  loading: boolean;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    await this.execute();
  }

  async fix() {
    await this.execute(false);
    await this.execute();
  }

  async execute(dryRun: boolean = true) {
    this.loading = true;
    const resp = await this.http
      .post<BillingAuditDto>(`${environment.config.endpoints.billing}/billing/audit`, {
        dryRun
      })
      .toPromise();
    this.missingKeys = resp.missingKeys;
    this.thisShouldBeThereKeys = resp.thisShouldBeThereKeys;
    this.loading = false;
  }

}
