import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { BoxComponent } from './box/box.component';
import { MainComponent } from './main/main.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([])
  ],
  declarations: [
    MainComponent,
    BoxComponent
  ]
})
export class LayoutsModule { }
