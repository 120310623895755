import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HealthService } from '@core/services/health.service';
import { environment } from '@env/environment';
import { SocketServerStatus } from '@shared/models/socket-server-status';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Meeting } from '../meetings/meetings.component';

interface HealthResp {
  name: string;
  version: number;
  buildAt: string;
  coreAt: string;
  startAt: string;
  lastRequestAt: string;
  exceptions: number;
  requests: number;
  totalRequests: number;
  uptime: number;
  status: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  loadingHealth = false;
  services = [];
  refresh$ = new Subject();

  sockets: number;
  meetings: number;

  get lastCheck() {
    return this.healthService.lastCheck;
  }

  get status$() {
    return this.healthService.statuses$;
  }

  constructor(
    private healthService: HealthService,
    private http: HttpClient
  ) { }

  ngOnInit() {

    interval(10000)
      .pipe(takeUntil(this.refresh$))
      .subscribe(() => this.load());

    this.load();
    this.healthService.start()
  }

  ngOnDestroy() {
    this.refresh$.next();
    this.healthService.stop()
  }

  async load() {

    await this.http
      .get<SocketServerStatus>(`${environment.config.endpoints.socketio}`)
      .toPromise()
      .then((status) => {
        this.sockets = status.sockets.length;
      }, () => {
        this.sockets = null;
      });

    await this.http
      .get<{ meetings: Meeting[] }>(`${environment.config.endpoints['stream']}/stream/meetings`, {
        params: {
          status: 'Started'
        }
      })
      .toPromise()
      .then(res => {
        this.meetings = res.meetings.length;
      });
  }

}
