import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@core/services/theme.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {

  get theme() {
    return this.themeService.theme;
  }

  constructor(
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
  }

  switchTheme(val) {
    this.themeService.toggle(val);
  }

}
