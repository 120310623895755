import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { LayoutsModule } from '@layouts/layouts.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { DateFnsModule } from 'ngx-date-fns';
import { NgPipesModule } from 'ngx-pipes';
import { WebStorageModule } from 'ngx-store';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { BillingShellComponent } from './billing/@shell/shell.component';
import { AnonymousUsersComponent } from './billing/anonymous-users/anonymous-users.component';
import { ExpiredComponent } from './billing/expired/expired.component';
import { StripeEventsComponent } from './billing/stripe-events/stripe-events.component';
import { SubscriptionLogsComponent } from './billing/subscription-logs/subscription-logs.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HealthComponent } from './health/health.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { MessagesComponent } from './messages/messages.component';
import { MessagesOverviewComponent } from './messages/overview/overview.component';
import { OfflineComponent } from './offline/offline.component';
import { ReportsComponent } from './reports/reports.component';
import { RulesetsComponent } from './rulesets/rulesets.component';
import { SessionsComponent } from './sessions/sessions.component';
import { SettingsComponent } from './settings/settings.component';
import { SwaggerComponent } from './swagger/swagger.component';
import { DeleteTeamFormComponent } from './tools/delete-team/delete-team-form/delete-team-form.component';
import { DeleteTeamComponent } from './tools/delete-team/delete-team.component';
import { ReloadFormComponent } from './tools/reload-form/reload-form.component';
import { ReloadComponent } from './tools/reload/reload.component';
import { ToolsComponent } from './tools/tools.component';
import { ZombiesComponent } from './tools/zombies/zombies.component';
import { SubAcFixComponent } from './tools/sub-ac-fix/sub-ac-fix.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
    WebStorageModule,
    DateFnsModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    RouterModule.forRoot(ROUTES, {
      // enableTracing: true
    }),
    CoreModule,
    SharedModule,
    LayoutsModule,
  ],
  declarations: [
    AnonymousUsersComponent,
    AppComponent,
    BillingShellComponent,
    DashboardComponent,
    DeleteTeamComponent,
    DeleteTeamFormComponent,
    DeleteTeamFormComponent,
    ExpiredComponent,
    HealthComponent,
    LoginComponent,
    LogoutComponent,
    MeetingsComponent,
    MessagesComponent,
    MessagesOverviewComponent,
    OfflineComponent,
    ReloadComponent,
    ReloadFormComponent,
    ReloadFormComponent,
    ReportsComponent,
    RulesetsComponent,
    SessionsComponent,
    SettingsComponent,
    StripeEventsComponent,
    SubscriptionLogsComponent,
    SubscriptionLogsComponent,
    SwaggerComponent,
    ToolsComponent,
    ZombiesComponent,
    SubAcFixComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
