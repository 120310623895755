import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamMembersServiceApi } from '@echofin/libraries';
import { environment } from '@env/environment';

@Injectable()
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private membersApi: TeamMembersServiceApi,
    ) { }

  async getNotificationsLog(teamId: string): Promise<any> {
    const url = `${environment.config.endpoints.notification}/notification/log`;
    return this.http.get<any>(url, {
      params: {
        teamId
      }
    }).toPromise();
  }
  async getNotificationsLogHistory(teamId: string, from: string): Promise<any> {
    const url = `${environment.config.endpoints.notification}/notification/log`;
    return this.http.get<any>(url, {
      params: {
        teamId,
        from
      }
    }).toPromise();
  }
  getMembers(selectedTeamId, userIds) {
    return this.membersApi
      .SearchMembers({
        TeamId: selectedTeamId,
        UserIds: userIds
      })
      .toPromise()
      .then(members => members.sort((a, b) => (a['user'].username > b['user'].username) ? 1 : ((b['user'].username > a['user'].username) ? -1 : 0)));
  }

}
