import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Meeting {
  chatroomId?: string;
  config?: string;
  creatorId?: string;
  startedAt?: string;
  status?: string;
  stoppedAt?: string;
  teamId?: string;
  duration?: string;
  server?: string;
}

export interface JitsiServer {
  name: string;
  stats: {
    rooms: {
      participant_count: number;
      jid: string;
      chatroomId: string;
      participants: {
        jid: string
        email: string
        display_name: string
      }[];
    }[];
  };
}

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsComponent implements OnInit {

  meetings$: Observable<Meeting[]>;
  servers$: Observable<JitsiServer[]>;

  showParticipants = {};

  teamNames = {};

  loadTeams$ = new Subject<string[]>();

  lastUpdate;
  loading = false;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {

    await this.loadTeamNames();

    this.refresh();
  }

  refresh() {
    this.lastUpdate = Date.now();
    this.getMeetings();
    this.getServers();
  }

  async loadTeamNames() {
    const resp = await this.http
      .get<{ teamNames: { id: string, name: string }[] }>(`${environment.config.endpoints.team}/team/teams/all-names`)
      .toPromise();
    this.teamNames = resp.teamNames.reduce((l, i) => {
      l[i.id] = i.name;
      return l;
    },                                     {});
  }

  getMeetings() {
    this.meetings$ = this.http
      .get<{ meetings: Meeting[] }>(`${environment.config.endpoints['stream']}/stream/meetings`)
      .pipe(
        map(res => {
          res.meetings.sort((a, b) => a.startedAt > b.startedAt ? -1 : 1);
          res.meetings.forEach(m => {
            if (m.stoppedAt != null) {
              const diff = new Date(m.stoppedAt).getTime() - new Date(m.startedAt).getTime();
              const date = new Date(diff);
              m.duration = date.toISOString().substr(11, 8);
            }
          });

          this.loadTeams$.next(res.meetings.map(m => m.teamId));

          return res.meetings;
        })
      );
  }

  getServers() {
    this.servers$ = this.http
      .get<{ servers: JitsiServer[] }>(`${environment.config.endpoints['stream']}/stream/servers`)
      .pipe(
        map(res => {
          res.servers?.forEach(s => {
            if (s.stats.rooms.length) {
              s.stats.rooms.forEach(r => {
                r.chatroomId = r.jid.split('@')[0];
              });
            }
          });
          return res.servers;
        })
      );
  }

}
