<nav class="navbar navbar-expand sticky-top">
  <div class="container size-xxl">
    <div class="collapse navbar-collapse d-flex align-items-center justify-content-between">
      <ul class="navbar-nav">
        <ng-container *ngFor="let m of menu">
          <li class="nav-item" *ngIf="!m.disabled">
            <a class="nav-link" [routerLink]="m.route" routerLinkActive="active">
              <span class="icon"><span class="{{m.icon}} fa-fw"></span></span>
              <span class="label">{{m.label}}</span>
            </a>
          </li>
        </ng-container>
      </ul>
      <div class="d-flex align-items-center">
        <div class="navbar-text ms-auto text-white">{{user?.username}}</div>
        <div class="divider"></div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/logout']">
              <span class="fas fa-sign-out-alt fa-fw"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="page-wrapper">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
