import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'trimDomain'
})
export class TrimDomainPipe implements PipeTransform {
  constructor() { }
  transform(url) {
    return url.replace(`.${environment.config.domain}`, '');
  }
}
