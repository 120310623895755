import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SessionModel } from '@echofin/libraries/api/session/models/session-model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-zombies',
  templateUrl: './zombies.component.html',
  styleUrls: ['./zombies.component.scss']
})
export class ZombiesComponent implements OnInit {

  sessions: SessionModel[];
  loading: boolean;

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    this.loading = true;
    const resp = await this.http
      .post<{ sessions: SessionModel[] }>(`${environment.config.endpoints.session}/session/cleanup`, {
        dryRun: true
      })
      .toPromise();
    this.sessions = resp.sessions;
    this.loading = false;
  }

  async remove() {
    this.loading = true;
    const resp = await this.http
      .post<{ sessions: SessionModel[] }>(`${environment.config.endpoints.session}/session/cleanup`, {
        dryRun: false
      })
      .toPromise();
    await this.load();
    this.loading = false;
  }
}
