import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription-logs',
  templateUrl: './subscription-logs.component.html',
  styleUrls: ['./subscription-logs.component.scss']
})
export class SubscriptionLogsComponent implements OnInit {

  @Input() id;

  logs: any[];

  showDetails = {};

  get m() {
    return this.activeModal;
  }

  constructor(
    private http: HttpClient,
    private activeModal: NgbActiveModal
  ) { }

  async ngOnInit() {
    const resp = await this.http.get<{ logs: any[] }>(`${environment.config.endpoints.billing}/billing/subscriptions/${this.id}/logs`).toPromise();
    resp.logs.sort((a, b) => a.created < b.created ? -1 : 1);
    this.logs = resp.logs.map(l => { l.data = JSON.parse(l.data); return l; });
  }

}
