import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReloadFormComponent } from '../reload-form/reload-form.component';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent {

  constructor(
    private modal: NgbModal) { }

  openReload() {
    this.modal.open(ReloadFormComponent);
  }

}
