<app-page size="md">
  <div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div><strong>Theme</strong></div>
          <div>Change current theme</div>
        </div>
        <div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
              [ngModel]="theme" value='light' (change)="switchTheme($event.target.value)">
            <label class="form-check-label" for="inlineRadio1">Light</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
              [ngModel]="theme" value='dark' (change)="switchTheme($event.target.value)">
            <label class="form-check-label" for="inlineRadio2">Dark</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-page>
