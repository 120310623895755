import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  apiDomains = [];

  constructor(
    private toastr: ToastrService
  ) {
    this.apiDomains = Object
      .keys(environment.config.endpoints)
      .reduce((l, k) => {
        if (!l[environment.config.endpoints[k]]) {
          l.push(environment.config.endpoints[k]);
        }
        return l;
      }, []);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const r = request.clone({
      withCredentials: this.apiDomains.contains(this.parseUrl(request.url).origin)
    });
    return next.handle(r).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.toastr.error('You are unauthorized to perform this action');
        }
        if (error.status === 405) {
          this.toastr.error('This action is not allowed');
        }
        return throwError(error);
      }));
  }

  parseUrl(url) {
    const m = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);
    const r = {
      hash: m[10] || '',
      host: m[3] || '',
      hostname: m[6] || '',
      href: m[0] || '',
      origin: m[1] || '',
      pathname: m[8] || (m[1] ? '/' : ''),
      port: m[7] || '',
      protocol: m[2] || '',
      search: m[9] || '',
      username: m[4] || '',
      password: m[5] || '' // password
    };
    if (r.protocol.length == 2) {
      r.protocol = 'file:///' + r.protocol.toUpperCase();
      r.origin = r.protocol + '//' + r.host;
    }
    r.href = r.origin + r.pathname + r.search + r.hash;
    return r;
  }
}
