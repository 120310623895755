import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-store';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  @LocalStorage('audit_theme')
  theme: 'light' | 'dark' = 'light';

  theme$ = new Subject<string>();

  constructor() {
    this.theme$.next(this.theme);
  }

  toggle(newTheme: 'light' | 'dark') {
    this.theme = newTheme;
    this.theme$.next(newTheme);
  }

}
