import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AnonGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // console.log('activate anon: check server');
    const resp = await this.authService.getUserId();
    if (!resp) {
      // console.log('activate main: server offline');
      return this.router.parseUrl('/offline');
    }

    if (!resp.id) {
      return true
    }

    // console.log('activate anon: get user');
    const user = await this.authService.getUser();
    if (!user || !user['settings'] || !user['settings'].system) {
      // console.log('activate anon: user not found or not have permissions');
      return true;
    }

    // console.log(`activate anon: user ${user.id} found - redirect to home`);
    return this.router.parseUrl('/audit');
  }
}
