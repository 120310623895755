<app-page>
  <div class="toolbar">
    <div class="menu">
      <span>Show:</span>
      <div class="btn-group">
        <button class="btn btn-outline-success" [ngClass]="{active:filters.status===sessionStatus.OPEN}"
          (click)="filters.status=sessionStatus.OPEN; refresh()">Open</button>
        <button class="btn btn-outline-primary" [ngClass]="{active:filters.status==='ALL'}"
          (click)="filters.status='ALL'; refresh()">All</button>
      </div>
    </div>
    <div class="menu">
      <span class="label-refresh" *ngIf="lastUpdate" ngbTooltip="Updated">
        {{lastUpdate|date:"HH:mm:ss"}}</span>
      <button class="btn-refresh" [ngClass]="{active:loading}" (click)="refresh()"></button>
    </div>
  </div>
  <div class="d-flex">
    <div class="flex-grow-1">
      <div class="card">
        <div class="card-header">Sessions</div>
        <table class="table" *ngIf="profiles.length; else empty">
          <thead>
            <tr>
              <th width="40px;">
                <button (click)="toogleAll()" class="btn btn-light py-0"
                  style="line-height:20px;height:20px;">+</button>
              </th>
              <th class="text-center" width="40px;" ngbTooltip="Status">Sta</th>
              <th>Profile</th>
              <th class="text-center" width="90px;">Sess. ({{totalSessions}})</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let profile of profiles">
              <tr>
                <th class="text-center">
                  <button (click)="openedSessions[profile.userId] = !openedSessions[profile.userId]"
                    class="btn btn-light py-0" style="line-height:20px;height:20px;">
                    {{openedSessions[profile.userId] ? '-' : "+"}}
                  </button>
                </th>
                <td class="text-center">
                  <ng-container [ngSwitch]="profile.status">
                    <span *ngSwitchCase="'idle'" class="text-warning" ngbTooltip="idle">⬤</span>
                    <span *ngSwitchCase="'online'" class="text-success" ngbTooltip="online">⬤</span>
                    <span *ngSwitchCase="'offline'" class="text-muted" ngbTooltip="offline">⬤</span>
                    <span *ngSwitchCase="'busy'" class="text-danfger" ngbTooltip="busy">⬤</span>
                    <span *ngSwitchDefault class="text-danger" ngbTooltip="unknown">⚠</span>
                  </ng-container>
                </td>
                <td>
                  <a [routerLink]="[ '/audit/users', profile.userId ]">{{usernames[profile.userId]}}</a>
                  <span> - {{profile.userId}}</span>
                  <span *ngIf="profile.hasAlert" class="text-danger" ngbTooltip="zombie sessions detected"> ⚠</span>
                </td>
                <td class="text-center">{{profile.sessions.length}}</td>
              </tr>
              <tr *ngIf="openedSessions[profile.userId]" class="bg-light">
                <td>&nbsp;</td>
                <td colspan="4" class="p-0">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center" width="40px;" ngbTooltip="Status">Sta</th>
                        <th width="140px;">Created</th>
                        <th>Session</th>
                      </tr>
                    </thead>
                    <ng-container *ngFor="let session of profile.sessions">
                      <tr>
                        <td class="text-center">
                          <ng-container [ngSwitch]="session._status">
                            <span *ngSwitchCase="'idle'" class="text-warning" ngbTooltip="idle">⬤</span>
                            <span *ngSwitchCase="'open'" class="text-success" ngbTooltip="open">⬤</span>
                            <span *ngSwitchCase="'removed'" class="text-muted"
                              [ngbTooltip]="session.closedAt | date:'short'">⬤</span>
                            <span *ngSwitchDefault class="text-danger" ngbTooltip="zombie session detected">⚠</span>
                          </ng-container>
                        </td>
                        <td>{{session.createdAt | date:'short'}}</td>
                        <td>{{session.sessionId}}</td>
                      </tr>
                    </ng-container>
                  </table>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div style="width: 250px;" class="ms-4">
      <div class="card overflow-auto" style="height:300px">
        <div class="card-header">Sockets ({{sockets.length}})</div>
        <table class="table table-sockets">
          <tbody>
            <tr *ngFor="let socket of sockets">
              <td>{{socket}}</td>
              <td width="40px" class="text-center">
                <button class="btn btn-sm btn-outline-danger py-0" (click)="disconnect(socket)">
                  <span class="fas fa-times"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-page>

<!-- <pre>{{sessions|json:2}}</pre> -->
<ng-template #empty>
  <div class="card-body text-center">No sessions were found</div>
</ng-template>
