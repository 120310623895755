<app-page>
  <div class="card">
    <div class="card-header">
      🔨 Reload Teams
    </div>
    <div class="card-body">
      <div>Send a reload command to all or some teams</div>
      <button class="btn btn-primary btn-sm" (click)="openReload()">Open reload</button>
    </div>
  </div>
</app-page>
