import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

  loading: boolean;
  items: any[];

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    this.loading = true;
    const resp = await this.http
      .post<{ subscriptions: any[] }>(`${environment.config.endpoints.billing}/billing/subscriptions/expired`, {
        dryRun: true
      })
      .toPromise();
    this.items = resp.subscriptions;
    this.loading = false;
  }

  async remove() {
    this.loading = true;
    await this.http
      .post<{ subs: any[] }>(`${environment.config.endpoints.billing}/billing/subscriptions/expired`, {
        dryRun: false
      })
      .toPromise();
    await this.load();
    this.loading = false;
  }
}
