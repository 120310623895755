import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { EmptyComponent } from './components/empty/empty.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageComponent } from './components/page/page.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextCopyDirective } from './directives/text-copy.directive';
import { DateTimeFormatPipe } from './pipes/datetime-format.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TrimDomainPipe } from './pipes/trim-domain.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
  ],
  declarations: [
    KeysPipe,
    SafePipe,
    TrimDomainPipe,
    DateTimeFormatPipe,

    TextCopyDirective,

    FooterComponent,
    SpinnerComponent,
    EmptyComponent,
    PageComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,

    SpinnerComponent,
    EmptyComponent,
    FooterComponent,
    PageComponent,

    TextCopyDirective,

    KeysPipe,
    SafePipe,
    TrimDomainPipe,
    DateTimeFormatPipe,
  ]
})
export class SharedModule { }
