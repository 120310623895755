import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-team-form',
  templateUrl: './delete-team-form.component.html',
  styleUrls: ['./delete-team-form.component.scss']
})
export class DeleteTeamFormComponent {

  teamId: string;

  get m() {
    return this.modal;
  }

  constructor(
    private modal: NgbActiveModal,
    private http: HttpClient
  ) { }

  async delete() {
    await this.http.delete(`${environment.config.endpoints.team}/team/teams/${this.teamId}`).toPromise();

    this.modal.close();
  }
}
