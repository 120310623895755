import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { parseISO, subSeconds } from 'date-fns';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

export interface HealthResp {
  buildAt?: string;
  buildAtDate?: Date;
  coreAt?: string;
  coreAtDate?: Date;
  exceptions?: number;
  healthUrl?: string;
  lastRequestAt?: string;
  lastRequestAtDate?: Date;
  name?: string;
  requests?: number;
  startAt?: string;
  startAtDate?: Date;
  status?: string;
  swaggerUrl?: string;
  totalRequests?: number;
  uptime?: number;
  uptimeDate?: Date;
  version?: number;
}
const active = ['email', 'postmark', 'share', 'stream'];
@Injectable({
  providedIn: 'root'
})
export class HealthService {

  lastCheck: number;
  check: Subscription

  statuses$ = new BehaviorSubject<HealthResp[]>([]);

  constructor(
    private http: HttpClient
  ) {

  }

  start() {
    this.check = interval(5000).subscribe(() => this.loadHealth());
    this.loadHealth()
  }

  stop() {
    this.check.unsubscribe()
  }

  loadHealth() {
    this.lastCheck = Date.now();
    const statuses = [];
    active.forEach((k) => {

      const status = {
        name: k,
        healthUrl: `${environment.config.endpoints[k]}/${k}/health`,
        swaggerUrl: `${environment.config.endpoints[k]}/${k}/swagger`,
        status: 'Not Load'
      };

      this.http.get<HealthResp>(status.healthUrl).subscribe((resp) => {
        if (resp?.version !== 2) return;
        statuses.push({
          ...status,
          ...resp,
          ...{
            uptimeDate: subSeconds(Date.now(), resp.uptime),
            buildAtDate: parseISO(resp.buildAt),
            coreAtDate: parseISO(resp.coreAt),
            startAtDate: parseISO(resp.startAt),
            lastRequestAtDate: parseISO(resp.lastRequestAt),
            status: 'Running'
          }
        });
        this.statuses$.next(statuses);
      }, (err) => {
        status.status = 'LoadFailed';
        statuses.push(status);
        this.statuses$.next(statuses);
      });
    });
  }
}
