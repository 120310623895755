import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Message } from '@echofin/libraries/api/message/models/message';
import { environment } from '@env/environment';
import { differenceInDays, parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-message-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class MessagesOverviewComponent implements OnInit {
  messages: any[];
  loading: boolean;
  teamNames = {};
  allTeams: { id: string; name: string; }[];
  teams: { id: string; name: string; }[];
  filterTeam: any;
  timestamp: any;
  filterTeamText: any;
  messages$: Observable<Message[]>;
  constructor(
    private http: HttpClient,
  ) { }

  async ngOnInit() {
    await this.loadTeams();
    await this.load();
  }

  async loadTeams() {
    const resp = await this.http
      .get<{ teamNames: { id: string, name: string }[] }>(`${environment.config.endpoints.team}/team/teams/all-names`)
      .toPromise();
    this.allTeams = resp.teamNames.filter(x => x.id !== 'tnm_echofihq');
    this.allTeams.sort((a, b) => a.name < b.name ? -1 : 1);
    this.filterTeamList();
    this.teamNames = this.allTeams.reduce((l, i) => {
      l[i.id] = i.name;
      return l;
    },                                    {});
  }

  filterTeamList(term?: string) {
    this.teams = this.allTeams.filter(x => x.id !== 'tnm_echofihq' && (x.name.startsWith(term) || !term));
  }

  async load() {
    this.loading = true;
    const params = {
      limit: 100,
      timestamp: this.timestamp || new Date().toISOString(),
      excludeChatrooms: null,
      includeChatrooms: null
    };

    const hqchatrooms = await this.http
      .get<any[]>(`${environment.config.endpoints.team}/team/teams/tnm_echofihq/rooms`)
      .toPromise();
    if (hqchatrooms) {
      params.excludeChatrooms = hqchatrooms.map(x => x.id);
    }

    if (this.filterTeam) {
      const chatrooms = await this.http
        .get<any[]>(`${environment.config.endpoints.team}/team/teams/${this.filterTeam.id}/rooms`)
        .toPromise();
      if (chatrooms) {
        params.includeChatrooms = chatrooms.map(x => x.id);
      }
    }

    this.messages$ = this.http
      .post<{ messages: any[] }>(`${environment.config.endpoints.message}/message/messages/all`, params)
      .pipe(
        map(resp => {
          resp.messages.forEach((m, i) => {
            if (i === 0) {
              m.divider = true;
            }
            if (resp.messages[i + 1]) {
              if (differenceInDays(parseISO(m.displayTimestamp), parseISO(resp.messages[i + 1].displayTimestamp)) > 1) {
                m.divider = true;
              }
            }
          });
          return resp.messages as Message[];
        })
      );
  }

  async setTimestamp(ts: string) {
    this.timestamp = parseISO(ts).toISOString();
    await this.load();
  }
}
