import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { STRIPE_EVENT_CATEGORIES } from '@shared/models/stripe-event-categories';
import { StripeEvent } from '@shared/models/StripeEvent';

@Component({
  selector: 'app-stripe-events',
  templateUrl: './stripe-events.component.html',
  styleUrls: ['./stripe-events.component.scss']
})
export class StripeEventsComponent implements OnInit {

  private allEvents: StripeEvent[] = [];
  events: StripeEvent[] = [];
  page = 0;
  showData = {};
  showError = {};

  eventCategories = STRIPE_EVENT_CATEGORIES;

  eventActionColors = {
    detached: '#f44336',
    payment_succeeded: '#4CAF50',
    succeeded: '#4CAF50',
    source: '#2196F3',
    failed: '#f44336',
    voided: '#f44336',
    canceled: '#f44336',
    attached: '#8BC34A',
    finalized: '#4CAF50',
    payment_action_required: '#f44336',
    updated: '#8BC34A',
    payment_failed: '#f44336',
    subscription: '#4CAF50',
    created: '#4CAF50',
  };

  filter = { categories: {} };

  get categoryFilterLabel() {
    const filterCategories = Object.keys(this.filter.categories).filter(x => this.filter.categories[x]);
    if (filterCategories.length === 0) {
      return 'all';
    }
    let label = '';
    for (let i = 0; i < 2; i++) {
      if (filterCategories[i]) {
        if (label) {
          label += ', ';
        }
        const l = this.eventCategories.find(e => e.key === filterCategories[i]);
        label += l.label;
      }
    }
    if (filterCategories.length > 2) {
      label += ` and ${filterCategories.length - 2} more`;

    }
    return label;
  }

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    const url = `${environment.config.endpoints.billing}/billing/events?page=${this.page}`;
    const results = await this.http.get<{ events: StripeEvent[] }>(url).toPromise();
    if (results.events) {
      results.events = results.events.map((e) => {
        e.parsedData = JSON.parse(e.data);
        const c = e.type.split('.');
        e.category = c[0];
        e.subcategory = c.slice(1).join('.');
        e.categoryColor = `#${this.shade(this.int_to_rgba(this.hash(e.category)), 30)}`;
        e.subcategoryColor = `#${this.shade(this.int_to_rgba(this.hash(e.subcategory)), 30)}`;
        return e;
      });
      this.allEvents = this.allEvents.concat(results.events);
      this.filterEvents();
    }
  }

  filterEvents() {
    const filterCategories = Object.keys(this.filter.categories).filter(x => this.filter.categories[x]);
    if (filterCategories.length === 0) {
      this.events = this.allEvents;
      return;
    }
    this.events = this.allEvents.filter(e => filterCategories.find(x => e.type.startsWith(x)));
  }

  clearCategoryFilter() {
    this.filter.categories = {};
    this.filterEvents();
  }

  async loadMore() {
    this.page++;
    await this.load();
  }

  async refresh() {
    this.page = 0;
    this.allEvents = [];
    this.events = [];
    await this.load();
  }

  reconcile() {

  }

  hash(word) {
    let h = 0;
    for (let i = 0; i < word.length; i++) {
      h = word.charCodeAt(i) + ((h << 5) - h);
    }
    return h;
  }

  // Change the darkness or lightness
  shade(color, prc) {
    const num = parseInt(color, 16),
      amt = Math.round(2.55 * prc),
      R = (num >> 16) + amt,
      G = (num >> 8 & 0x00FF) + amt,
      B = (num & 0x0000FF) + amt;
    return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 +
      (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 +
      (B < 255 ? B < 1 ? 0 : B : 255))
      .toString(16)
      .slice(1);
  }

  // Convert init to an RGBA
  int_to_rgba(i) {
    const color = ((i >> 24) & 0xFF).toString(16) +
      ((i >> 16) & 0xFF).toString(16) +
      ((i >> 8) & 0xFF).toString(16) +
      (i & 0xFF).toString(16);
    return color;
  }

  toHex(input) {
    let hash = 0;
    if (input.length === 0) return hash;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    let color = '';
    for (let i = 0; i < 3; i++) {
      const v = (hash >> (i * 8)) & 255;
      color += ('00' + v.toString(16)).substr(-2);
    }
    return color;
  }

  private hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i) {
    const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }
}
