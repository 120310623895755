import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  @ViewChild('sso', { static: true }) sso: ElementRef;

  ssoUrl = `${environment.config.endpoints.authentication}/authentication/sso.html`;
  loaded = false

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  ngOnInit() {
    setTimeout(async () => {
      await this.authService.logout();
      await this.router.navigate(['/login'], {
        queryParams: {
          msg: "logout"
        }
      });
    }, 500);
  }

  async ssoLoad() {
    this.authService.sso = this.sso;
  }
}
