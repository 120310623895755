<div class="d-flex">
  <div class="me-2" style="width:250px;">
    <div class="card">
      <div class="card-header">Test Coverage</div>
      <div class="list-group list-group-flush">
        <button class="list-group-item list-group-item-action" [ngClass]="{active:view=='Api'}" (click)="view = 'Api'">Api</button>
        <button class="list-group-item list-group-item-action" [ngClass]="{active:view=='Dashboard'}" (click)="view = 'Dashboard'">Dashboard</button>
        <button class="list-group-item list-group-item-action" [ngClass]="{active:view=='WebClient'}" (click)="view = 'WebClient'">WebClient</button>
      </div>
    </div>
  </div>
  <div class="w-100">
    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'Api'">
        <div class="card mb-2">
          <div class="card-header">
            <h4 class="d-flex">2019-01-31 <a href="/assets/reports/2019-01-31.coverage/api.html" target="_blank"
                class="btn btn-sm btn-outline-primary ms-auto">
                Open in new tab</a></h4>
          </div>
          <iframe style="height:650px" src="/assets/reports/2019-01-31.coverage/api.html" height="100%" frameborder="0"></iframe>
        </div>
        <div class="card mb-2">
          <div class="card-header">
            <h4 class="d-flex">2019-01-23 <a href="/assets/reports/2019-01-23.coverage/api.html" target="_blank"
                class="btn btn-sm btn-outline-primary ms-auto">
                Open in new tab</a></h4>
          </div>
          <iframe style="height:650px" src="/assets/reports/2019-01-23.coverage/api.html" height="100%" frameborder="0"></iframe>
        </div>
      </ng-container>
      <div *ngSwitchDefault>
        <div class="card mb-2">
          <h4 class="text-muted">no view yet</h4>
        </div>
      </div>
    </ng-container>
  </div>
</div>
