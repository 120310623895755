<app-page>
  <div class="card">
    <div class="card-header">
      💣 Delete Team
    </div>
    <div class="card-body">
      <div>Remove ownership and all members. Hide from discovery. Make private. Tag as deleted.</div>
      <button class="btn btn-primary btn-sm" (click)="openDelete()">Open delete</button>
    </div>
  </div>
</app-page>
