<div class="modal-header">
    <h5 class="modal-title">Delete Team</h5>
    <button type="button" class="close" (click)="m.close()">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label>Team Id:</label>
        <input class="form-control form-control-sm" [(ngModel)]="teamId" required>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="m.close()">Close</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="delete()">DELETE</button>
</div>
