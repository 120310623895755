<app-page>
  <div class="toolbar">
    <div class="menu"></div>
    <div class="menu">
      <span class="label-refresh" *ngIf="lastUpdate" ngbTooltip="Updated">
        {{lastUpdate|date:"HH:mm:ss"}}</span>
      <button class="btn-refresh" [ngClass]="{active:loading}" (click)="refresh()"></button>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <ng-container *ngIf="(meetings$ | async) as meetings; else spinnerTmpl">
        <ng-container *ngIf="meetings.length; else emptyTmpl">
          <div class="card">
            <div class="card-header">History</div>
            <table class="table">
              <thead>
                <tr>
                  <th style="width:25px;">&nbsp;</th>
                  <th>Time</th>
                  <th>Team</th>
                  <th>Chatroom</th>
                  <th>Creator</th>
                  <th>Server</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let meeting of meetings;let i = index">
                  <tr
                    *ngIf="!meetings[i-1] || (meetings[i-1].startedAt|date:'MM-dd') > (meetings[i].startedAt|date:'MM-dd')">
                    <th colspan="6">
                      {{meeting.startedAt|date:"MMM dd, yyyy"}}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ng-container [ngSwitch]="meeting.status">
                        <span *ngSwitchCase="'Pending'" class="text-warning" ngbTooltip="Pending">⬤</span>
                        <span *ngSwitchCase="'Started'" class="text-success" ngbTooltip="Started">⬤</span>
                        <span *ngSwitchCase="'Stopped'" class="text-muted" ngbTooltip="Stopped">⬤</span>
                        <span *ngSwitchDefault class="text-danger" ngbTooltip="unknown">⚠</span>
                      </ng-container>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <span>{{meeting.startedAt|date:"HH:mm"}}</span>
                        <ng-container *ngIf="meeting.stoppedAt">
                          <span>-{{meeting.stoppedAt|date:"HH:mm"}}</span>
                          <span>|{{meeting.duration}}</span>
                        </ng-container>
                      </div>
                    </td>
                    <td>
                      <a [routerLink]="['/audit/teams/',meeting.teamId]">
                        {{teamNames[meeting.teamId]}}</a></td>
                    <td>{{meeting.chatroomId}}</td>
                    <td>
                      <a [routerLink]="['/audit/users/',meeting.creatorId]">
                        {{meeting.creatorId}}</a>
                    </td>
                    <td>
                      {{meeting.server|trimDomain}}
                    </td>
                  </tr>

                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-4">
      <ng-container *ngIf="(servers$ | async) as servers; else spinnerTmpl">
        <ng-container *ngIf="servers.length; else emptyTmpl">
          <ng-container *ngFor="let server of servers">
            <div class="card mb-2">
              <div class="card-header">{{server.name}}</div>
              <ng-container *ngIf="server.stats.rooms.length; else noMeetingsTmpl">
                <table class="table">
                  <tbody>
                    <ng-container *ngFor="let room of server.stats.rooms">
                      <tr>
                        <td>
                          {{room.chatroomId}}</td>
                        <td style="width:50px">
                          <button (click)="showParticipants[room.jid] = !showParticipants[room.jid]"
                            class="btn btn-light py-0" style="line-height:20px;height:20px;">
                            {{room.participant_count}}
                            <span *ngIf="showParticipants[room.jid]" class="fas fa-chevron-up"></span>
                            <span *ngIf="!showParticipants[room.jid]" class="fas fa-chevron-down"></span>
                          </button>
                        </td>
                      </tr>
                      <ng-container *ngIf="showParticipants[room.jid]">
                        <ng-container *ngFor="let participant of room.participants">
                          <tr><td colspan="2">{{participant.display_name}}</td></tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</app-page>

<ng-template #spinnerTmpl>
  <div class="card">
    <app-spinner></app-spinner>
  </div>
</ng-template>

<ng-template #emptyTmpl>
  <div class="card">
    <app-empty></app-empty>
  </div>
</ng-template>

<ng-template #noMeetingsTmpl>
  <div class="card-body text-center">
    no meetings
  </div>
</ng-template>
