export const STRIPE_EVENT_CATEGORIES = [
  {
    key: 'account',
    label: 'account',
    color: 'red'
  },
  {
    key: 'application_fee',
    label: 'application_fee',
    color: 'red'
  },
  {
    key: 'balance',
    label: 'balance',
    color: 'red'
  },
  {
    key: 'capability',
    label: 'capability',
    color: 'red'
  },
  {
    key: 'charge',
    label: 'charge',
    color: 'red'
  },
  {
    key: 'checkout',
    label: 'checkout',
    color: 'red'
  },
  {
    key: 'coupon',
    label: 'coupon',
    color: 'red'
  },
  {
    key: 'credit_note',
    label: 'credit_note',
    color: 'red'
  },
  {
    key: 'customer',
    label: 'customer',
    color: 'red'
  },
  {
    key: 'file',
    label: 'file',
    color: 'red'
  },
  {
    key: 'invoice',
    label: 'invoice',
    color: 'red'
  },
  {
    key: 'invoiceitem',
    label: 'invoiceitem',
    color: 'red'
  },
  {
    key: 'issuing_authorization',
    label: 'issuing_authorization',
    color: 'red'
  },
  {
    key: 'issuing_card',
    label: 'issuing_card',
    color: 'red'
  },
  {
    key: 'issuing_cardholder',
    label: 'issuing_cardholder',
    color: 'red'
  },
  {
    key: 'issuing_transaction',
    label: 'issuing_transaction',
    color: 'red'
  },
  {
    key: 'mandate',
    label: 'mandate',
    color: 'red'
  },
  {
    key: 'order',
    label: 'order',
    color: 'red'
  },
  {
    key: 'order_return',
    label: 'order_return',
    color: 'red'
  },
  {
    key: 'payment_intent',
    label: 'payment_intent',
    color: 'red'
  },
  {
    key: 'payment_method',
    label: 'payment_method',
    color: 'red'
  },
  {
    key: 'payout',
    label: 'payout',
    color: 'red'
  },
  {
    key: 'person',
    label: 'person',
    color: 'red'
  },
  {
    key: 'plan',
    label: 'plan',
    color: 'red'
  },
  {
    key: 'price',
    label: 'price',
    color: 'red'
  },
  {
    key: 'product',
    label: 'product',
    color: 'red'
  },
  {
    key: 'radar',
    label: 'radar',
    color: 'red'
  },
  {
    key: 'recipient',
    label: 'recipient',
    color: 'red'
  },
  {
    key: 'reporting',
    label: 'reporting',
    color: 'red'
  },
  {
    key: 'review',
    label: 'review',
    color: 'red'
  },
  {
    key: 'setup_intent',
    label: 'setup_intent',
    color: 'red'
  },
  {
    key: 'sigma',
    label: 'sigma',
    color: 'red'
  },
  {
    key: 'sku',
    label: 'sku',
    color: 'red'
  },
  {
    key: 'source',
    label: 'source',
    color: 'red'
  },
  {
    key: 'subscription_schedule',
    label: 'subscription_schedule',
    color: 'red'
  },
  {
    key: 'tax_rate',
    label: 'tax_rate',
    color: 'red'
  },
  {
    key: 'topup',
    label: 'topup',
    color: 'red'
  },
  {
    key: 'transfer',
    label: 'transfer',
    color: 'red'
  },
];
