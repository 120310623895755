import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @Input() size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'xxl';
  @Input() full = false;
  container: string;
  constructor() { }

  ngOnInit(): void {
    this.container = this.full ? 'container-fluid' : 'container';
  }

}
