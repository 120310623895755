import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-rulesets',
  templateUrl: './rulesets.component.html',
  styleUrls: ['./rulesets.component.scss']
})
export class RulesetsComponent implements OnInit {
  templates: any[] = [];
  rules: any[] = [];
  loading: boolean;
  selectedTemplate: any;
  showPanel = 'TEMPLATE';
  newRule: string;
  newTemplate: string;

  constructor(
    private http: HttpClient,
  ) { }

  async ngOnInit() {
    await this.getRules();
    await this.getTemplates();
  }

  private async getRules() {
    this.rules = await this.http
      .get(`${environment.config.endpoints.accesscontrol}/accesscontrol/rules`)
      .toPromise()
      .then(response => {
        return response['rules'];
      });
  }

  private async getTemplates() {
    return this.http
      .get(`${environment.config.endpoints.accesscontrol}/accesscontrol/templates`)
      .toPromise()
      .then(response => {
        this.templates = response['templates'].map((t) => {
          t.rules = this.rules
            .map((r: string) => r)
            .reduce((o, k) => {
              o[k] = 'INHERIT';
              if (t.ruleset.allowed.indexOf(k.toUpperCase()) !== -1) {
                o[k] = 'ALLOW';
              }
              if (t.ruleset.denied.indexOf(k.toUpperCase()) !== -1) {
                o[k] = 'DENY';
              }
              return o;
            },      {});
          return t;
        });
      });
  }

  async setRule(template, rule, effect) {
    template.rules[rule] = effect;
    const ruleset = Object.keys(template.rules).reduce((rv, x) => {
      if (template.rules[x] === 'ALLOW') { rv.allowed.push(x); }
      if (template.rules[x] === 'DENY') { rv.denied.push(x); }
      return rv;
    },                                                 { allowed: [], denied: [] });
    await this.http
      .put(`${environment.config.endpoints.accesscontrol}/accesscontrol/templates/${template.id}`, {
        ruleset
      })
      .toPromise();
  }
}
