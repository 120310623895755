import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MainGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const resp = await this.authService.getUserId();
    if (!resp) {
      // console.log('activate main: server offline');
      return this.router.parseUrl('/offline');
    }

    if (!resp.id) {
      return this.router.parseUrl('/login');
    }

    // console.log('activate main: get user');
    const user = await this.authService.getUser();
    if (!!user && user['settings'] && user['settings'].system) {
      // console.log(`activate anon: user ${user.id} found`);
      return true;
    }

    // console.log('activate main: user not found - redirect to login');
    this.toastr.error("unauthorized")
    return this.router.parseUrl('/login');
  }
}
