<!-- <div class="d-flex align-items-center mx-auto" style="max-width:450px">
    <div class="form-group flex-grow-1">
        <input #searchEmail class="form-control" placeholder="email" (keydown.enter)="search()">
    </div>
    <button class="btn btn-primary ms-2" (keydown.enter)="search()">Search</button>
</div>
<pre>{{users}}</pre> -->

<div class="toolbar">
  <div class="menu">
    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" [(ngModel)]="testStripe">
        test stripe mode
      </label>
    </div>
  </div>
  <div class="menu">
    <button class="btn btn-outline-dark btn-sm" (click)="load()">⭮</button>
  </div>
</div>
<div class="table-wrapper">
  <table class="table">
    <thead>
      <tr>
        <th>created</th>
        <th>email</th>
        <th>platform</th>
        <th>connected</th>
        <th>plan</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let user of users">
        <tr>
          <td>{{user.created|date:"MM/dd HH:mm:ss"}}</td>
          <td>{{user.email}}</td>
          <td>
            <a [href]="'https://dashboard.stripe.com' + (testStripe?'/test':'') + '/customers/'+ user.platformCustomerId"
              target="_blank">
              {{user.platformCustomerId}}</a></td>
          <td>
            <a [href]="'https://dashboard.stripe.com/'+ user.accountId + (testStripe?'/test':'') + '/customers/'+ user.connectedCustomerId"
              target="_blank">
              {{user.connectedCustomerId}}</a></td>
          <td>{{user.plan.description}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
