import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamMembersServiceApi, TeamRolesServiceApi, TeamTeamsServiceApi } from '@echofin/libraries';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  constructor(
    private teamApi: TeamTeamsServiceApi,
    private roleApi: TeamRolesServiceApi,
    private membersApi: TeamMembersServiceApi,
    private http: HttpClient
  ) { }

  getMembers(selectedTeamId) {
    return this.membersApi
      .SearchMembers({
        TeamId: selectedTeamId,
        Leaved: true
      })
      .toPromise()
      .then(members => members.sort((a, b) => (a['user'].username > b['user'].username) ? 1 : ((b['user'].username > a['user'].username) ? -1 : 0)));
  }

  getTeamRooms(teamId) {
    return this.teamApi
      .GetRooms(teamId)
      .toPromise()
      .then((rooms) => rooms.sortBy({ name: 'asc' }));
  }

  getRoles(selectedTeamId) {
    return this.roleApi
      .GetRoles(selectedTeamId)
      .toPromise()
      .then((roles) => roles.sortBy({ name: 'asc' }));
  }
}
