import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datetimeFormat' })
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    return super.transform(value, 'MMM dd, HH:mm:ss');
  }
}
