<nav class="navbar navbar-expand navbar-dark bg-secondary sticky-top">
  <div class="container">
    <div class="collapse navbar-collapse">
      <div class="divider"></div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/billing/events']" routerLinkActive="active">Events</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/billing/anonymous']" routerLinkActive="active">Anonymous</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/audit/billing/expired']" routerLinkActive="active">Expired
            Subscriptions</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container px-0 py-4">
  <router-outlet></router-outlet>
</div>
