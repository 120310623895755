<ng-container *ngIf="!ssoLoading;else ssoLoadingTmpl">
  <h1 class="text-center mb-2">{{emoji}}</h1>
  <h2 class="text-center mb-4">audit</h2>
  <div [ngClass]="{loading:loading}">
    <div *ngIf="alert" class="alert alert-{{alert.cssClass}} text-center">
      <strong>{{alert.message}}</strong>
    </div>
    <div class="mb-2">
      <input type="text" name="username" [disabled]="loading" class="form-control" placeholder="username"
        [(ngModel)]="identity" (keydown.enter)="login()">
    </div>
    <div class="mb-2 d-flex">
      <input type="password" name="password" [disabled]="loading" class="form-control" placeholder="password"
        [(ngModel)]="password" (keydown.enter)="login()">
        <button [disabled]="loading" class="btn btn-primary ms-2" (click)="login()">login</button>
    </div>
  </div>
</ng-container>

<iframe #sso [src]="ssoUrl | safe" (load)="ssoLoad()" height="0" frameborder="0"></iframe>

<ng-template #ssoLoadingTmpl>
  <div class="text-center text-muted"> loading</div>
</ng-template>
