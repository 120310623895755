import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {

  @Input() text = 'empty';
  @Input() subtext = 'empty';

  constructor() { }

  ngOnInit(): void {
    this.subtext = this.quotes[Math.round(Math.random() * this.quotes.length)];
  }

  quotes = [
    'I feel empty inside.',
    'Not happy. Not sad. Just empty.',
    'You can’t cry when you’re already empty.',
    'Silence isn’t empty it’s full of answers.',
    'How can emptiness be so heavy?',
    'Why does the feeling of emptiness occupy so much space?',
    'She tries to smile, To feel happy, But she feels so Empty.',
    'The beginning of love is a horror of emptiness.',
    'To fake it is to stand guard over emptiness.',
    'We become aware of the void as we fill it.',
    'The inner emptiness is the door to God.',
    'A busy life is filled with tremendous emptiness.',
    'That feeling when you are not necessarily sad, but you feel really empty.',
    'I wanted to write down exactly what I felt but somehow the paper stays empty and I could not have described it any better.',
    'Illusion is needed to disguise the emptiness within.',
    'Sometimes you can only feel something by its absence. By the empty spaces it leaves behind.',
    'The rest of my life stretches out as an emptiness before me.',
    'If emptiness is endless, then everything rests in emptiness.',
    'Nothingness not being nothing, nothingness being emptiness.',
    'She was tired, with that tiredness that only emptiness brings.',
    'But to me nothing, the negative, the empty, is exceedingly powerful.',
    'What had been quiet and restful was now silent and empty.',
    'This will be a winter so desolate, only memory can fill the emptiness.',
    'Every word is like an unnecessary stain on silence and nothingness.',
    'It is beautiful, it is endless, it is full and yet seems empty. It hurts us.',
    'Life is empty and meaningless because life is empty and meaningless.',
    'I decided I would fill the emptiness in me with God and with paint.',
    'If giving leaves you feeling empty, you’re giving too much to the wrong person.',
    'The greatest tragedies in life is not untimely death, but to live a life for emptiness.',
    'We often feel proud of our knowledge even when it is filled with emptiness.',
    'I am empty of everything. I am empty of everything but the thin, frail ghosts in my room.',
    'Pain can be vitalising; it gives intensity in the space of vagueness and emptiness.',
    'It’s not the feeling of completeness I need, but the feeling of not being empty.',
    'This world that I live in is empty and cold the loneliness cuts me and tortures my soul.',
    'In all our searching, the only thing we’ve found that makes the emptiness bearable is each other.',
    'The artist’s job is not to succumb to despair but to find an antidote for the emptiness of existence.',
    'This was the truth at the core of my existence: this yawning emptiness, scantily clad in rage.',
    'There’s just something obvious about emptiness, even when you try to convince yourself otherwise.',
    'When you’re full of yourself, God can’t fill you. But when you empty yourself, God has a useful vessel.',
    'Depression isn’t just being a bit sad. It’s feeling nothing. It’s not wanting to be alive anymore.',
    'The funny thing about having all this so-called success is that behind it is a certain horrible emptiness.',
    'Always show kindness and love to others. Your words might be filling the empty places in someone’s heart.',
    'I felt myself being invaded through and through, I crumbled, disintegrated, and only emptiness remained.',
    'Moments of suffering are meant to empty us so that we may have the potential to tap into our truest depths.',
    'Emptiness is not a great loss. It creates a great opportunity to fill yourself again and again with great love.',
    'A sad soul needs an infinite horizon which can throw all his sorrow into the silence of the eternal emptiness!',
    'I swear, I end up feeling empty, like you’ve taken something out of me and I have to search my body for scars.',
    'Become totally empty. Quiet the restlessness of the mind. Only then will you witness everything unfolding from emptiness.',
    'The more space and emptiness you can create in yourself, then you can let the rest of the world come in and fill you up.',
    'There are so many empty people walking around on this little planet. Lonely people. Angry people. Bitter. Forgotten.',
    'Emptiness which is conceptually liable to be mistaken for sheer nothingness is in fact the reservoir of infinite possibilities.',
    'The void inside me starts to fill, but my heart has holes, and whatever it holds will run out, leaving me empty once again.',
    'One must know oneself. If this does not serve to discover truth, it at least serves as a rule of life and there is nothing better.',
    'I said nothing for a time, just ran my fingertips along the edge of the human-shaped emptiness that had been left inside me.',
    'But what I did sense was an emptiness like a black hole inside of him, and there was no predicting what might emerge from a place like that.',
    'When I feel truly alone, with a sense of being lost, even empty inside, it is then I realize I have unknowingly moved away from God, so I move back.',
    'Bowed by the weight of centuries he leans upon his hoe and gazes on the ground, the emptiness of ages in his face, and on his back the burden of the world.',
    'There is no such thing as an empty space or an empty time. There is always something to see, something to hear. In fact, try as we may to make a silence, we cannot.',
    'For a long time now my heart has had its shutters closed, its steps deserted, formerly a tumultuous hotel, but now empty and echoing like a great empty tomb.',
    'You can only fit so many words in a postcard, only so many in a phone call, only so many into space before you forget that words are sometimes used for things other than filling emptiness.',
    'Everyday we used to slip away to talk. We would talk about the goodness of your heart. Now it seems I admit that never exist at all and it leaves me feeling empty with this hollow chord.',
    'You are not feeling empty because you don’t have much money. You are feeling empty because you have not yet encountered your real self, you have not come to your authentic individuality.',
    'There’s a big difference, I discovered, between wanting to die and not wanting to live. When you want to die, you at least have a goal. When you don’t want to live, you’re really just empty.', ];
}
