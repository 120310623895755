<app-page>
  <div class="modal-header">
    <h5 class="modal-title">Reload Teams</h5>
    <button type="button" class="close" (click)="m.close()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Message:</label>
      <textarea class="form-control form-control-sm" rows="2" [(ngModel)]="model.message"></textarea>
    </div>
    <div class="form-group">
      <label>Timeout:</label>
      <input class="form-control form-control-sm" [(ngModel)]="model.timeout" placeholder="in seconds...">
      <small class="form-text text-muted">Set -1 for no timeout</small>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary" (click)="m.close()">Close</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="reload()">Reload</button>
  </div>
</app-page>
