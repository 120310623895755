import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionSessionsServiceApi } from '@echofin/libraries';
import { UserExtResp } from '@echofin/libraries/api/profile/models/user-ext-resp';
import { SessionModel } from '@echofin/libraries/api/session/models/session-model';
import { environment } from '@env/environment';
import { SessionStatus, SocketServerStatus } from '@shared/models/socket-server-status';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit, OnDestroy {

  sockets: string[] = [];
  profiles: any[] = [];
  sessions: any[];
  loading = false;
  cols: any[];
  showDebugPanel = false;
  gridApi: any;

  openedSessions: { [key: string]: boolean } = {};
  sessionStatus = SessionStatus;
  filters = {
    status: SessionStatus.OPEN || 'ALL'
  };
  socketStarted: Date;
  usernames: any = {};

  socketSub: Subscription;
  lastUpdate: number;
  refreshInterval = 10 * 1000;
  totalSessions: number;

  constructor(
    private http: HttpClient,
    private sessionService: SessionSessionsServiceApi,
  ) { }

  async ngOnInit() {
    this.socketSub = interval(this.refreshInterval)
      .subscribe(async () => {
        await this.refresh();
      });
    await this.refresh();
  }

  ngOnDestroy(): void {
    if (this.socketSub) {
      this.socketSub.unsubscribe();
    }
  }

  async refresh() {
    this.loading = true;
    await this.refreshSessions();
    await this.refreshSocket();
    this.lastUpdate = Date.now();
    this.loading = false;
  }

  async refreshSessions() {
    this.usernames = {};
    const sessions = await this.sessionService
      .GetSessions({
        status: this.filters.status
      })
      .toPromise();

    if (sessions.length) {
      this.profiles = await this.groupSessions(sessions);
    }
    this.sessions = sessions;
  }

  async refreshSocket() {
    const status = await this.http
      .get<SocketServerStatus>(`${environment.config.endpoints.socketio}`)
      .toPromise()
      .catch(err => {
        return {
          started: null,
          sockets: []
        };
      });
    this.socketStarted = status.started;
    this.sockets = status.sockets;

    this.profiles.forEach((profile) => {
      profile.hasAlert = profile.sessions.some((s: SessionModel) => this.sockets.indexOf(s.sessionId) === -1 && s.status === 'OPEN');
      profile.sessions = profile.sessions.map((s: SessionModel & any) => {
        if (s.status === 'REMOVED') s._status = 'removed';
        if (s.status === 'OPEN') {
          if (s.lastIdleStatus && s.lastIdleStatus > s.lastAvailableStatus) s._status = 'idle';
          else s._status = 'open';
          if (this.sockets.indexOf(s.sessionId) === -1) s._status = 'zombie';
        }
        return s;
      });
    });
  }

  private async groupSessions(dbSessions: any[]) {
    this.totalSessions = dbSessions.length;

    const grouped = dbSessions.reduce(
      (rv, x) => {
        (rv[x.userId] = rv[x.userId] || []).push(x);
        return rv;
      },
      {});

    const profiles = await this.http.post(`${environment.config.endpoints.profile}/profile/profiles/getprofiles`, {
      Ids: Object.keys(grouped)
    }).toPromise();

    this.usernames = (profiles as UserExtResp[]).reduce(
      (o, i) => {
        o[i.id] = i.username;
        return o;
      },
      {});

    return Object.keys(grouped)
      .map((userId: string) => {
        let status = 'offline';
        if (grouped[userId].some((s: SessionModel) => s.status === 'OPEN' || !s.closedAt)) {
          if (grouped[userId].every((s: SessionModel) => s.lastIdleStatus && s.lastIdleStatus > s.lastAvailableStatus)) {
            status = 'idle';
          } else {
            status = 'online';
          }
        }

        return {
          userId,
          status,
          sessions: grouped[userId],
        };
      });
  }

  async disconnect(socket) {
    await this.http.post(`${environment.config.endpoints.socketio}/disconnect`, {
      sessions: [socket]
    }).toPromise();
    await this.refresh();
  }

  toogleAll() {
    Object.keys(this.profiles).forEach(p => {
      this.openedSessions[this.profiles[p].userId] = true;
    });
  }
}
