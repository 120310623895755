import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(
    private http: HttpClient,
    ) { }

  async getAccessRules(principal: string, resource: string): Promise<any> {
    const url = `${environment.config.endpoints.accesscontrol}/accesscontrol/access/all`;
    return this.http.get<any>(url, {
      params: {
        principal,
        resource
      }
    }).toPromise();
  }

  async getAllRules(): Promise<string[]> {
    const url = `${environment.config.endpoints.accesscontrol}/accesscontrol/rules`;
    return this.http.get<string[]>(url).toPromise().then(response => response['rules']);
  }
}
