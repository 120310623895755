import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

export interface MenuItem {
  label?: string;
  icon?: string;
  route?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  menu: MenuItem[] = [
    { label: 'dashboard', icon: 'fal fa-tachometer-alt', route: '/audit/dashboard' },
    { label: 'sessions', icon: 'fal fa-list', route: '/audit/sessions' },
    { label: 'meetings', icon: 'fal fa-webcam', route: '/audit/meetings' },
    { label: 'messages', icon: 'fal fa-comments', route: '/audit/messages' },
    { label: 'teams', icon: 'fal fa-users-class', route: '/audit/teams' },
    { label: 'users', icon: 'fal fa-user-friends', route: '/audit/users' },
    { label: 'rulesets', icon: 'fal fa-ruler-combined', route: '/audit/rulesets' },
    { label: 'billing', icon: 'fal fa-credit-card', route: '/audit/billing' },
    { label: 'tools', icon: 'fal fa-tools', route: '/audit/tools' },
    { label: 'swagger', icon: 'fal fa-brackets-curly', route: '/audit/swagger' },
    { label: 'settings', icon: 'fal fa-cog', route: '/audit/settings' },
  ];

  constructor(
    private authService: AuthService,
  ) { }

  get user() {
    return this.authService.user;
  }
}
