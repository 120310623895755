<div class="container">
  <div class="card">
    <table class="table">
      <thead>
        <tr style="height: 90px;">
          <th>&nbsp;</th>
          <th *ngFor="let template of templates" style="vertical-align:middle;transform: rotate(-45deg);">
            {{template.id}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rule of rules">
          <th>{{rule}}</th>
          <th *ngFor="let template of templates" class="text-center">
            <div class="btn-group btn-group-permissions">
              <!-- <button class="btn btn-outline-danger" (click)="setRule(template, rule,'DENY')"
                [ngClass]="{active:template.rules[rule] === 'DENY'}">X</button>
              <button class="btn btn-outline-secondary" (click)="setRule(template, rule,'INHERIT')"
                [ngClass]="{active:template.rules[rule] === 'INHERIT'}">-</button>
              <button class="btn btn-outline-success" (click)="setRule(template, rule,'ALLOW')"
                [ngClass]="{active:template.rules[rule] === 'ALLOW'}">✔</button> -->
              <button class="btn btn-outline-danger" [ngClass]="{active:template.rules[rule] === 'DENY'}">X</button>
              <button class="btn btn-outline-secondary"
                [ngClass]="{active:template.rules[rule] === 'INHERIT'}">-</button>
              <button class="btn btn-outline-success" [ngClass]="{active:template.rules[rule] === 'ALLOW'}">✔</button>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #empty>
  <div class="card-body p-4 text-center">
    No templates found
  </div>
</ng-template>