<app-page>
  <div class="d-flex justify-content-between align-items-start">
    <div>Sessions in <b>Echofin.Session</b> that have stuck in OPEN state will be switched in REMOVED state. This
      will fix idle issues.</div>
    <button class="btn btn-sm btn-outline-secondary border-0" (click)="load()">
      <span class="fas fa-sync-alt" [ngClass]="{spin:loading}"></span>
    </button>
  </div>
  <div class="card">
    <div class="card-header">
      🧟‍♂️ Zombies Sessions
    </div>
    <ng-container *ngIf="sessions; else loader">
      <div class="card-body">
        <button class="btn btn-primary btn-sm" [disabled]="loading" (click)="remove()">
          {{loading ? 'working...' : 'Kill the Zombies 🪓'}}</button>
      </div>
      <ng-container *ngIf="sessions.length; else noItems">
        <table class="table mt-2">
          <thead>
            <tr>
              <th>SessionId</th>
              <th>UserId</th>
              <th>CreatedAt</th>
              <th>ClosedAt</th>
              <th>IsIdle</th>
              <th>Status</th>
              <th>Events</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let s of sessions">
              <tr>
                <th>{{s.sessionId}}</th>
                <th>{{s.userId}}</th>
                <th>{{s.createdAt}}</th>
                <th>{{s.closedAt}}</th>
                <th>{{s.lastIdleStatus && s.lastIdleStatus > s.lastAvailableStatus}}</th>
                <th>{{s.status}}</th>
                <th>{{s.events}}</th>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>
</app-page>

<ng-template #loader>
  <div class="text-center text-muted p-4">
    loading...
  </div>
</ng-template>

<ng-template #noItems>
  <div class="text-center text-muted p-4">
    No zombies! 🎉🙌
  </div>
</ng-template>
