
export interface SocketServerStatus {
  status: SessionStatus;
  started: Date;
  sockets: string[];
}

export enum SessionStatus {
  OPEN = 'OPEN',
  KILLED = 'KILLED',
  REMOVED = 'REMOVED'
}
