import { AnonGuard } from '@core/guards/anon.guard';
import { MainGuard } from '@core/guards/main.guard';
import { BoxComponent } from '@layouts/box/box.component';
import { MainComponent } from '@layouts/main/main.component';
import { BillingShellComponent } from './billing/@shell/shell.component';
import { AnonymousUsersComponent } from './billing/anonymous-users/anonymous-users.component';
import { ExpiredComponent } from './billing/expired/expired.component';
import { StripeEventsComponent } from './billing/stripe-events/stripe-events.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HealthComponent } from './health/health.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MeetingsComponent } from './meetings/meetings.component';
import { MessagesComponent } from './messages/messages.component';
import { MessagesOverviewComponent } from './messages/overview/overview.component';
import { OfflineComponent } from './offline/offline.component';
import { ReportsComponent } from './reports/reports.component';
import { RulesetsComponent } from './rulesets/rulesets.component';
import { SessionsComponent } from './sessions/sessions.component';
import { SettingsComponent } from './settings/settings.component';
import { SwaggerComponent } from './swagger/swagger.component';
import { DeleteTeamComponent } from './tools/delete-team/delete-team.component';
import { ReloadComponent } from './tools/reload/reload.component';
import { SubAcFixComponent } from './tools/sub-ac-fix/sub-ac-fix.component';
import { ToolsComponent } from './tools/tools.component';
import { ZombiesComponent } from './tools/zombies/zombies.component';

export const ROUTES = [{
  path: 'audit',
  component: MainComponent,
  canActivate: [MainGuard],
  children: [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'health', component: HealthComponent },
    { path: 'rulesets', component: RulesetsComponent },
    { path: 'meetings', component: MeetingsComponent },
    { path: 'sessions', component: SessionsComponent },
    { path: 'reports', component: ReportsComponent },
    { path: 'settings', component: SettingsComponent },
    {
      path: 'messages',
      component: MessagesComponent, children: [{
        path: 'overview',
        component: MessagesOverviewComponent
      },
      { path: '', redirectTo: 'overview', pathMatch: 'full' }
      ]
    },
    { path: 'swagger', component: SwaggerComponent },
    {
      path: 'tools', component: ToolsComponent,
      children: [
        { path: 'sub-ac-fix', component: SubAcFixComponent },
        { path: 'reload', component: ReloadComponent },
        { path: 'delete-team', component: DeleteTeamComponent },
        { path: 'zombies', component: ZombiesComponent },
        { path: '', redirectTo: 'sub-ac-fix', pathMatch: 'full' }
      ]
    },
    {
      path: 'billing',
      component: BillingShellComponent,
      children: [
        { path: 'anonymous', component: AnonymousUsersComponent },
        { path: 'events', component: StripeEventsComponent },
        { path: 'expired', component: ExpiredComponent },
        { path: '', redirectTo: 'events', pathMatch: 'full' }
      ]
    },
    {
      path: 'teams',
      loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule)
    },
    {
      path: 'users',
      loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
    },
    {
      path: '', redirectTo: 'dashboard', pathMatch: 'full'
    }]
}, {
  path: '',
  component: BoxComponent,
  children: [
    { path: 'login', canActivate: [AnonGuard], component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'offline', component: OfflineComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }]
}, {
  path: '',
  redirectTo: '/audit',
  pathMatch: 'full'
}];
