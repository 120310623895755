<app-page>
  <div class="toolbar">
    <div class="menu">
      <div ngbDropdown autoClose="outside" class="dropdown d-inline-block">
        <button class="btn btn-outline-secondary" ngbDropdownToggle>
          {{filterTeam ? filterTeam.name: 'all teams'}}
        </button>
        <div ngbDropdownMenu class="overflow-auto" style="max-height: 350px;">
          <button ngbDropdownItem (click)="filterTeam = null;load()">all teams</button>
          <div class="dropdown-divider"></div>
          <div class="px-2 mb-2">
            <input #filterTeamListInput class="form-control form-control-sm"
              (keyup)="filterTeamList($event.target.value)">
          </div>
          <ng-container *ngFor="let t of teams">
            <button ngbDropdownItem (click)="filterTeam=t;load()">
              {{t.name}}
            </button>
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <input type="datetime-local" class="form-control" (change)="setTimestamp($event.target.value)">
      </div>
    </div>
    <div class="menu">
      <button (click)="load()" class="btn btn-sm btn-light border py-0">⭮</button>
    </div>
  </div>

  <div class="table-wrapper mb-4">
    <ng-container *ngIf="(messages$ | async) as messages; else spinnerTmpl">
      <ng-container *ngIf="messages && messages.length">
        <table class="table">
          <thead>
            <tr>
              <th width="60px;">Created</th>
              <th width="120px;">Sender</th>
              <th width="120px;">Team / Chatroom</th>
              <th>Message</th>
              <th width="60px;">Type</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let m of messages">
              <tr *ngIf="m.divider">
                <th colspan="6">{{m.displayTimestamp | date:"yyyy-MM-dd"}}</th>
              </tr>
              <tr>
                <td> {{m.displayTimestamp | date:"HH:mm:ss"}} </td>
                <td style="max-width: 120px;">
                  {{m.sender.username}}
                  <a class="external-link" [routerLink]="['/audit/users',m.sender.id]"><span
                      class="fas fa-external-link-alt"></span></a>
                </td>
                <td style="max-width: 120px;">
                  <div>
                    {{m.chatroom.name}}
                    <a class="external-link" *ngIf="m.chatroom && m.chatroom.teamId"
                      [routerLink]="['/audit/chatrooms',m.chatroom.id]"><span
                        class="fas fa-external-link-alt"></span></a>
                  </div>
                  <div>
                    <em> {{teamNames[m.chatroom.teamId] || 'unknown'}}
                      <a class="external-link" *ngIf="m.chatroom && m.chatroom.teamId"
                        [routerLink]="['/audit/teams',m.chatroom.teamId]"><span
                          class="fas fa-external-link-alt"></span></a></em>
                  </div>
                </td>
                <td class="wrap">
                  <ng-container *ngIf="m.text">
                    {{m.text}}
                  </ng-container>
                  <ng-container *ngIf="m.files">
                    <ng-container *ngIf="m.files[0].mime.indexOf('image/')!==-1">
                      <div class="img"><a [href]="m.files[0].url" target="_blank">
                          <img [src]="m.files[0].url"></a></div>
                    </ng-container>
                    <ng-container *ngIf="m.files[0].mime.indexOf('image/')===-1">
                      <a [href]="m.files[0].url" target="_blank">{{m.files[0].filename}}</a>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="m.signal">
                    <div class="bg-light p-2 rounded" style="max-width:450px;overflow:auto;white-space:pre">{{m.signal|json}}</div>
                  </ng-container>
                  <ng-container *ngIf="m.secret">
                    <div class="bg-light p-2 rounded" style="max-width:450px;overflow:auto;white-space:pre">{{m.secret|json}}</div>
                  </ng-container>
                </td>
                <td>
                  <ng-container [ngSwitch]="m.type">
                    <span *ngSwitchCase="'TEXT'" class="d-block badge rounded-pill bg-primary">{{m.type}}</span>
                    <span *ngSwitchCase="'SIGNAL'" class="d-block badge rounded-pill bg-success">{{m.type}}</span>
                    <span *ngSwitchCase="'SECRET'" class="d-block badge rounded-pill bg-danger">{{m.type}}</span>
                    <span *ngSwitchCase="'SHOUT'" class="d-block badge rounded-pill bg-warning">{{m.type}}</span>
                    <span *ngSwitchDefault class="d-block badge rounded-pill bg-dark">{{m.type}}</span>
                  </ng-container>
                  <ng-container *ngIf="m.deleted">
                    <div class="test-center">
                      <span class="fas fa-times-circle text-danger"
                        [ngbTooltip]="m.deleted.timestamp | date:'yyyy-MM-dd HH:mm:ss'"></span>
                      DEL
                    </div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </div>

</app-page>

<ng-template #spinnerTmpl>
  <app-spinner></app-spinner>
</ng-template>
