<div class="toolbar">
  <div class="menu">
    <button class="btn btn-primary btn-sm" [disabled]="loading" (click)="remove()">
      {{loading ? 'working...' : 'Cancel Expired Subscriptions'}}</button>
  </div>
  <div class="menu">
    <button class="btn btn-outline-dark btn-sm" (click)="load()">⭮</button>
  </div>
</div>
<div class="table-wrapper" [ngClass]="{loading:loading}">
  <ng-container *ngIf="items; else loader">
    <ng-container *ngIf="items.length; else noItems">
      <table class="table">
        <thead>
          <tr>
            <th>Created</th>
            <th>Expired</th>
            <th>Id</th>
            <th>User</th>
            <th>Team</th>
            <th>Plan</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let i of items">
            <tr>
              <td>{{i.created|date:"MM-dd HH:mm"}}</td>
              <td>{{i.expire|date:"MM-dd HH:mm"}}</td>
              <td>{{i.id|slice:0:8}}</td>
              <td>{{i.userId}}</td>
              <td>{{i.teamId}}</td>
              <td>{{i.planId}}</td>
              <td>{{i.status}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
</div>

<ng-template #loader>
  <div class="card">
    <div class="text-center text-muted p-4">
      loading...
    </div>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="card">
    <div class="text-center text-muted p-4">
      No expired subscriptions! 🎉🙌
    </div>
  </div>
</ng-template>
