<div class="modal-header">
  <h5 class="modal-title">Subscription Logs</h5>
  <button type="button" class="close" (click)="m.close()">
    <span>&times;</span>
  </button>
</div>
<table class="table">
  <thead>
    <tr>
      <th>date</th>
      <th>message</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let log of logs">
      <tr>
        <td>{{log.created|date:"MMM dd, HH:mm:ss aa"}}</td>
        <td>{{log.message}}</td>
        <td>
          <a href="javascript:void(0)" (click)="showDetails[log.id] = !showDetails[log.id]" class="me-2">
            <span class="fas fa-angle-down"></span></a></td>
      </tr>
      <tr *ngIf="showDetails[log.id]">
        <td class="bg-light" colspan="3">
          <pre class="m-0 p-2">{{log.data|json}}</pre>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
