import { Component, Input, OnInit } from '@angular/core';
import { SessionReloadServiceApi } from '@echofin/libraries';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Team } from '@shared/models/team';

@Component({
  selector: 'app-reload-form',
  templateUrl: './reload-form.component.html'
})
export class ReloadFormComponent implements OnInit {

  @Input() teams: Team[] = [];

  model = {
    message: 'A new version is available. Reload to get it!',
    timeout: -1
  };
  teamNames: string[];

  constructor(
    private modal: NgbActiveModal,
    private reloadApi: SessionReloadServiceApi,
  ) { }

  get m() {
    return this.modal;
  }

  ngOnInit() {

    if (!this.teams.length) {
      this.teamNames = ['all'];
      return;
    }
    this.teamNames = this.teams.map(t => t.name);

    if (this.teamNames.length > 10) {
      const fullLength = this.teamNames.length;
      this.teamNames = this.teamNames.slice(0, 9);
      this.teamNames.push(`${fullLength - 10} more`);
    }
  }

  async reload() {
    await this.reloadApi
      .ReloadTeam({
        message: this.model.message,
        timeout: this.model.timeout,
        teams: this.teams.map(x => x.id),
      })
      .toPromise();
    this.modal.close();
  }

}
