<div class="toolbar">
  <div class="menu">
    <span>Type:</span>
    <div ngbDropdown autoClose="outside" class="dropdown d-inline-block">
      <button class="btn btn-outline-primary" ngbDropdownToggle>
        {{categoryFilterLabel}}
      </button>
      <div ngbDropdownMenu class="overflow-auto" style="max-height: 350px;">
        <button ngbDropdownItem (click)="clearCategoryFilter()">clear</button>
        <ng-container *ngFor="let ecat of eventCategories">
          <div ngbDropdownItem class="custom-control custom-checkbox pl-5">
            <input type="checkbox" class="custom-control-input" id="filter_category_{{ecat.key}}"
              [(ngModel)]="filter.categories[ecat.key]" (change)='filterEvents()'>
            <label class="custom-control-label" for="filter_category_{{ecat.key}}">
              {{ecat.label}}
              <span [ngStyle]="{color:ecat.color}">⬤</span>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="menu">
    <button class="btn btn-outline-dark btn-sm" (click)="refresh()">⭮</button>
  </div>
</div>

<div class="table-wrapper">
  <table class="table">
    <thead>
      <tr>
        <th>created</th>
        <th>subm/handl</th>
        <th>ids</th>
        <th colspan="2" class="text-center">type</th>
        <th>data</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ev of events">
        <tr>
          <td>{{ev.created|date:"MM/dd HH:mm:ss"}}<br>
            <span *ngIf="ev.account" [title]="ev.account" class="badge badge rounded-pill bg-primary">connected</span>
            <span *ngIf="!ev.account" title="platform" class="badge badge rounded-pill bg-secondary">platform</span>
          </td>
          <td>S:{{ev.submited|date:"MM/dd HH:mm:ss:SSS"}}<br>H:{{ev.handled|date:"MM/dd HH:mm:ss:SSS"}}</td>
          <td>{{ev.id}}<br>{{ev.parsedData?.object?.id}}</td>
          <td style="vertical-align:middle" class="text-end">
            {{ev.category}}
            <span [ngStyle]="{color:ev.categoryColor}">⬤</span>
          </td>
          <td style="vertical-align:middle">
            <span [ngStyle]="{color:eventActionColors[ev.subcategory]}">⬤</span>
            {{ev.subcategory}}
          </td>
          <td style="vertical-align:middle"><a (click)="showData[ev.id] = !showData[ev.id]">[show]</a></td>
        </tr>
        <tr *ngIf="showData[ev.id]">
          <td colspan="8">
            <div *ngIf="ev.error" class="alert alert-danger text-center">{{ev.error}}</div>
            <div class="overflow-auto bg-light" style="max-height:450px">
              <pre>{{ev.parsedData | json}}</pre>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="card-footer text-center">
    <button class="btn btn-outline-dark btn-sm" (click)="loadMore()">load more</button>
  </div>
</div>
